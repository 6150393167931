.login-wrapper{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    -webkit-box-orient:vertical;
    -webkit-box-direction:normal;
    -ms-flex-direction:column;
    flex-direction:column;
    width:100%;
    padding:30px 0
}
.login-wrapper .form__group{
    margin-bottom:15px;
    overflow:auto
}
.login-wrapper .form__group--error .form__input{
    border-color:#ff7175
}
.login-wrapper .form__group--error .form__input:focus{
    border-color:#ff7175
}
.login-wrapper .form__group--error .form__error-message{
    display:inline-block
}
.login-wrapper .form__label{
    display:inline-block;
    max-width:100%;
    margin-bottom:5px;
    font-weight:600
}
.login-wrapper .form__input{
    display:block;
    width:100%;
    height:44px;
    padding:6px 12px;
    background-color:#fff;
    border:1px solid #3b444c;
    border-radius:10px;
    font-size:14px;
    line-height:1.42857;
    color:#555;
    -webkit-transition:all .3s ease;
    transition:all .3s ease
}
.login-wrapper .form__input:focus{
    border-color:#32c3de;
    outline:0;
    box-shadow:none
}
.login-wrapper .form__button{
    display:inline-block;
    margin-bottom:0;
    text-align:center;
    -ms-touch-action:manipulation;
    touch-action:manipulation;
    cursor:pointer;
    border:2px solid transparent;
    padding:11px 20px;
    font-size:16px;
    line-height:1.42857;
    border-radius:10px;
    font-weight:600;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    color:#3b444c;
    background-color:transparent;
    border-color:#3b444c;
    vertical-align:middle;
    -webkit-transition:all .3s ease;
    transition:all .3s ease
}
.login-wrapper .form__button:focus,.login-wrapper .form__button:hover{
    background-color:#3b444c;
    color:#fff
}
.login-wrapper .form__error-message{
    display:none;
    width:100%;
    padding:10px;
    color:#ff7175
}
.login-logo{
    width:100%;
    height:65px;
    margin:0 0 25px 0;
    background-image:url(../img/rwa-logo-mobile@2x.png);
    background-repeat:no-repeat;
    background-position:50% 50%;
    background-size:contain
}
@media (min-width:768px){
    .login-wrapper{
        padding:75px 0 0 0
    }
    .login-logo{
        width:100%;
        height:65px;
        margin:0 0 50px 0;
        background-image:url(../img/rwa-logo@2x.png)
    }
}
@media (min-width:1025px){
    .login-wrapper{
        padding:125px 0 0 0
    }
    .login-wrapper .form{
        width:80%;
        margin:0 auto
    }
}