.video-modal{
    display:none;
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    width:100%;
    height:100%;
    margin:0 auto;
    background-color:rgba(86,81,83,.9);
    overflow:hidden;
    z-index:9999999
}
.video-modal__inner{
    position:relative;
    top:0;
    width:100%;
    height:100%;
    margin:0 auto;
    padding:0;
    -webkit-transform:scale(.75,.75);
    transform:scale(.75,.75);
    -webkit-transform-origin:50% 50%;
    transform-origin:50% 50%;
    background-color:#fff;
    border-radius:10px;
    overflow:hidden;
    opacity:0
}
.video-modal__inner-header{
    position:relative;
    display:block;
    width:100%;
    min-height:50px;
    margin:0;
    padding:15px;
    color:#fff;
    background-color:#3b444c;
    overflow:auto
}
.video-modal__inner-header a{
    color:#fff
}
.video-modal__inner-title{
    display:inline-block;
    width:65%;
    min-height:22px;
    margin:0;
    padding:0;
    font-size:16px;
    float:left;
}
.video-modal__inner-content{
    position:absolute;
    width:100%;
    height:calc(100% - 50px);
    background-color:#fff;
    -webkit-overflow-scrolling: touch !important;
    /*overflow: scroll !important;*/
    // overflow: auto !important;
    overflow: hidden;
}

.video-modal__buttons{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:end;
    -ms-flex-pack:end;
    justify-content:flex-end;
    width:35%;
    float:right;
    overflow:visible
}
.video-modal__buttons .video-modal__close-button{
    position:relative;
    font-size:14px;
    float:right;
    margin-left:15px
}
.video-modal__buttons .video-modal__close-button:before{
    content:'';
    display:inline-block;
    height:22px;
    width:22px;
    background-image:url(../img/svgs/icon-close.svg);
    background-repeat:no-repeat;
    background-size:cover;
    vertical-align:middle
}

@media (min-width:768px){
    .video-modal__inner-title{
        width:85%;
        padding:0 30px 0 0;
        font-size:20px
    }
    .video-modal__inner-header{
        display:-webkit-box;
        display:-ms-flexbox;
        display:flex;
        -webkit-box-pack:justify;
        -ms-flex-pack:justify;
        justify-content:space-between;
        -webkit-box-align:start;
        -ms-flex-align:start;
        align-items:flex-start
    }
    .video-modal__buttons{
        -webkit-box-pack:end;
        -ms-flex-pack:end;
        justify-content:flex-end;
        width:15%
    }
}

@media (min-width:1024px){
    .video-modal__inner{
        top:7.5%;
        width:90%;
        height:85%;
        -webkit-transform:scale(.85,.85);
        transform:scale(.85,.85)
    }
    .video-modal__inner-title{
        width:88%
    }
    .video-modal__buttons{
        width:12%
    }
}
@media (min-width:1200px){
    .video-modal__inner{
        width:940px;
        height:70%;
        top:15%
    }
}