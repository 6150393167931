.nav__shoulder{
    background:#3b444c
}
.nav__shoulder ul{
    margin:0;
    padding:0
}
.nav__shoulder ul li{
    display:inline;
    font-size:13px
}
.nav__shoulder ul li a{
    color:#fff;
    height:44px;
    line-height:44px;
    padding:0 10px;
    -webkit-transition:all .2s ease;
    transition:all .2s ease;
    display:inline-block
}
.nav__shoulder ul li a:focus,.nav__shoulder ul li a:hover{
    color:#4cfadf
}
.nav__shoulder ul li.last a{
    padding-right:0
}
.nav__mobile{
    width:100%;
    display:block;
    z-index:1
}
.nav__mobile .nav__mobile__stage__logo{
    display:inline-block;
    margin-left:10px;
    border-bottom:none;
    padding-bottom:0
}
.nav__mobile .nav__mobile__stage__logo img{
    max-width:195px
}
.nav__mobile .nav__mobile__call__button{
    display:block;
    float:right;
    padding:15px;
    border-radius:30px;
    background:#bf0026;
    margin:17px 0 6px 0;
    color:#fff;
    text-align:center;
    font-size:24px;
    line-height:2.05
}
.nav__mobile .nav__mobile__call__button:focus,.nav__mobile .nav__mobile__call__button:hover{
    background:#a02;
    cursor:pointer
}
.nav__mobile .nav__mobile__menu__button{
    display:block;
    position:absolute;
    top:10px;
    right:20px;
    padding:0;
    margin:0;
    text-indent:-9999px;
    width:44px;
    height:44px;
    background-image:url(../img/icon-hamburger@2x.png);
    background-size:contain;
    background-repeat:no-repeat;
    -webkit-transition:.3s ease-in-out;
    transition:.3s ease-in-out
}
.nav__mobile .nav__mobile__menu__button:focus,.nav__mobile .nav__mobile__menu__button:hover{
    text-decoration:none;
    cursor:pointer
}
.nav__mobile .nav__mobile__menu__button.active{
    opacity:0
}
.nav__mobile .nav__sidebar__content{
    display:none;
    position:fixed;
    width:85%;
    right:-85%;
    padding:55px 0 110px 0;
    background:#222;
    box-shadow:7px 0 15px -7px rgba(255,255,255,.15);
    color:#fff;
    height:100vh;
    display:block;
    z-index:999999;
    -webkit-transition:all .3s ease;
    transition:all .3s ease;
    overflow:auto
}
.nav__mobile .nav__sidebar__content .nav__sidebar__content__close{
    display:block;
    font-size:20px;
    top:20px;
    left:20px;
    position:absolute;
    text-align:center;
    width:20px;
    height:20px;
    text-indent:-9000em;
    background-image:url(../img/menu_close.png);
    background-repeat:no-repeat;
    background-position:top left
}
.nav__mobile .nav__sidebar__content .nav__sidebar__content__close:focus,.nav__mobile .nav__sidebar__content .nav__sidebar__content__close:hover{
    cursor:pointer
}
.nav__mobile .nav__sidebar__content.isOpen{
    display:block;
    right:0
}
.nav__mobile .nav__sidebar__content ul{
    margin:0;
    padding:0 18px;
    list-style:none
}
.nav__mobile .nav__sidebar__content ul ul{
    padding:20px 15px
}
.nav__mobile .nav__sidebar__content ul li{
    margin:0
}
.nav__mobile .nav__sidebar__content ul li a{
    display:block;
    color:#fff;
    padding:0;
    padding:10px 0;
    text-transform:capitalize
}
.nav__mobile .nav__sidebar__content ul li.nav__sidebar__content--has-children>a{
    position:relative;
    margin:0;
    padding:10px 0
}
.nav__mobile .nav__sidebar__content ul li>ul{
    padding:10px 15px;
    margin:0;
    background-color:#2d2d2d
}
.nav__mobile .nav__sidebar__content ul li>ul li a{
    font-weight:400;
    line-height: 1.4;
    border-bottom:0
}
.nav__mobile .header-list__link{
    border-bottom:1px solid rgba(255,255,255,.1)
}
@media (min-width:992px){
    .nav__mobile .nav__sidebar__content{
        top:64px;
        width:350px
    }
}
.mobile-nav-tabs-list a{
    font-weight: 400;
    // border-bottom:1px solid rgba(255,255,255,.1)
}
.mobile-nav-tab-name{
    position:relative;
    display:block;
    border-bottom:1px solid rgba(255,255,255,.1);
    text-transform:capitalize;
    font-weight:600
}
.nav__sidebar__content--has-children .mobile-nav-tab-name:after{
    position:absolute;
    display:inline-block;
    top:15px;
    right:15px;
    content:'';
    height:10px;
    width:18px;
    background-image:url(../img/svgs/iconmonstr-arrow-down-white.svg);
    background-repeat:no-repeat;
    background-size:contain;
    -webkit-transition:all .35s ease;
    transition:all .35s ease
}
.nav__sidebar__content--has-children .mobile-nav-tab-name.open:after{
    -webkit-transform:rotate(180deg);
    transform:rotate(180deg)
}
.nav-sidebar-sub-list{
    display:none
}
@media (min-width:480px){
    .nav__sidebar__content ul{
        padding:20px 20px 20px 60px
    }
}
@media (min-width:992px){
    .nav__mobile .nav__mobile__stage__logo{
        margin-left:0
    }
}
.nav-sidebar-favourites-list,.nav-sidebar-most-used-list,.nav-sidebar-projects-list,.nav-sidebar-recently-used-list{
    display:none;
    margin-bottom:0
}
.nav-sidebar-projects-sub-list__link{
    position:relative;
    display:block;
    text-transform:capitalize;
    font-weight:600!important;
    line-height: 1.4;
    padding-right:30px!important
}
.nav-sidebar-projects-sub-list__link:after{
    position:absolute;
    display:inline-block;
    top:15px;
    right:0;
    content:'';
    height:10px;
    width:18px;
    background-image:url(../img/svgs/iconmonstr-arrow-down-white.svg);
    background-repeat:no-repeat;
    background-size:contain;
    -webkit-transition:all .35s ease;
    transition:all .35s ease
}
.nav-sidebar-projects-sub-list__link.open:after{
    -webkit-transform:rotate(180deg);
    transform:rotate(180deg)
}
.nav-sidebar-sub-list{
    border-bottom:1px solid rgba(255,255,255,.1)
}
.nav-sidebar-projects-sub-list-projects{
    display:none
}
// ???
/* .nav-sidebar-projects-sub-list__item--$1 $2]{
    display:none!important
} */
@media (min-width:992px){
    .mobile-nav-settings-list{
        display:none
    }
}
@media (min-width:992px){
    .nav__sidebar__content-item{
        display:none
    }
}