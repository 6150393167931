#cookie-note{
    display:none;
    position:fixed;
    bottom:0;
    left:0;
    width:100%;
    height:auto;
    padding:18px 0 15px 0;
    background-color:rgba(255,255,255,.95);
    box-shadow:0 3px 24px 0 rgba(86,81,83,.13);
    z-index:1000
}
#cookie-note ul{
    display:inline-block;
    margin:0;
    padding:0
}
#cookie-note ul li{
    display:block;
    width:100%;
    list-style:none;
    float:left;
    margin:5px 0;
    margin-right:8px;
    clear:both
}
#cookie-note ul li:last-child{
    margin-bottom:0;
    margin-right:0
}
#cookie-message{
    display:inline-block;
    margin:14px 0 0 0;
    font-size:16px
}
#cookie-message a{
    color:#3b444c;
    text-decoration:none;
    border-bottom:1px solid #4cfadf;
    padding-bottom:2px
}
#cookie-message a:hover{
    border-color:#ffaa4c
}
#cookie-accept{
    display:inline-block;
    padding:12px 15px;
    float:right;
    color:#484745;
    border:1px solid #484745;
    border-radius:10px;
    text-decoration:none
}
#cookie-accept:focus,#cookie-accept:hover{
    color:#fff;
    background-color:#565153
}
@media (min-width:768px){
    #cookie-note ul li{
        display:inline-block;
        width:auto;
        clear:none;
        margin-bottom:0
    }
}
#third-party-cookie-iframe{
    display:none!important
}
#blocked-cookie-warning-wrapper{
    display:none;
    position:relative;
    padding:30px 0 0 0;
    z-index:99999
}
@media (min-width:1025px){
    #blocked-cookie-warning-wrapper{
        padding:125px 0 0 0
    }
}