#header{
    position:relative;
    width:100%;
    // min-height: 68.5px; // matching browser calculated height as a quick fix
    margin:0 0 25px 0;
    background-color:#fff;
    box-shadow:0 0 20px 1px rgba(0,0,0,.2);
    z-index:3;
}
.header__logo{
    max-width:300px
}
.header-nav-outer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.header-nav .header-list__link--no-link:focus,.header-nav .header-list__link--no-link:hover{
    cursor:pointer
}
.header-list{
    margin:0;
    padding:0
}
.header-list--utilities{
    margin: 0 !important;
    padding: 0 !important;
}
.header-list--utilities {
    a {
        cursor: pointer !important;
    }
}
.header-list--level-0{
    position:relative;
    display:none;
    width:100%;
    text-align:right
}
.header-list--level-1{
    width:100%;
    padding:0;
    background-color:#eee;
    float:left;
    text-align:left;
    border-top:1px solid rgba(0,0,0,.1);
    z-index:-1
}
.header-list--level-2{
    margin-top:-10px
}
.header-list--level-3{
    padding:0 0 0 10px
}
.header-list__item{
    list-style:none
}
.header-list__item--level-0{
    display:inline-block
}
/* .header-list__item--level-0:last-child a{
    border:none
} */
.header-list__item--no-report{
    display:none
}
.header-list__link{
    display:inline-block;
    width:100%;
    padding:14px 10px;
    text-decoration:none;
    text-transform:uppercase;
    font-size:14px;
    color:#3b444c;
    background-position:center top
}
.header-list__link--no-link:focus,.header-list__link--no-link:hover{
    color:#3b444c;
    cursor:default
}
.header-list__link--has-children:hover{
    background:url(../img/rwa-menu-down-arrow@2x.png) bottom center no-repeat scroll;
    background-size:16px 8px
}
.header-list__link>ul{
    box-shadow:0 5px 10px 0 rgba(0,0,0,.2);
    border-radius:0 0 10px 10px;
    position:absolute;
    left:0;
    top:44px;
    width:100%;
    -webkit-transition:.4s ease .25s;
    transition:.4s ease .25s;
    visibility:hidden;
    opacity:0;
    background-color:#fff
}
.header-list__link>ul:before{
    content:'';
    position:absolute;
    top:0;
    right:33.3%;
    width:1px;
    height:100%;
    background-color:rgba(0,0,0,.1)
}
.header-list__link>ul:after{
    content:'';
    position:absolute;
    top:0;
    right:66.6%;
    width:1px;
    height:100%;
    background-color:rgba(0,0,0,.1)
}
.header-list__link>ul>li{
    width:33.3%;
    float:left;
    min-height:50px
}
.header-list__link>ul>li:nth-child(1)>a,.header-list__link>ul>li:nth-child(2)>a,.header-list__link>ul>li:nth-child(3)>a{
    border-top:none
}
.header-list__link>ul>li>a{
    border-top:1px solid rgba(0,0,0,.1);
    border-right:0;
    padding:20px 10px
}
.header-list__link>ul>li:nth-child(3),.header-list__link>ul>li:nth-child(6){
    border-right:0
}
.header-list__link>ul ul{
    padding-bottom:15px
}
.header-list__link>ul ul li a{
    text-transform:none;
    font-weight:400;
    padding:8px 10px;
    border-right:0
}
.header-list__link>ul ul ul li a{
    font-weight:100
}
.header-list__link:hover,
.header-list__link:focus{
    color:#4cfadf
}
.header-list__link:hover>ul,
.header-list__link:focus>ul{
    visibility:visible;
    opacity:1
}
@media (min-width:992px){
    #header{
        position:fixed;
        top:0;
        left:0;
        width:100%;
        min-height: 103.5px; // matching browser calculated height as a quick fix
        margin:-40px 0 20px 0;
        padding:15px 0 0 0
    }
    .header__logo{
        position:relative;
        top:35px
    }
    .header-list__link{
        border-right:1px solid rgba(0,0,0,.1)
    }
}
#header-settings-list--switch-site{
    position:relative;
    top:1px;
    display:inline-block;
    height:14px;
    width:14px;
    padding:0 20px;
    text-indent:-9999px;
    overflow:hidden;
    background-size:contain;
    background-repeat:no-repeat;
    background-position:center;
    background-image:url(../img/icon-switch-site.png);
    border:none
}
#header-settings-list--switch-site:focus,#header-settings-list--switch-site:hover{
    background-image:url(../img/icon-switch-site-hover.png)
}
#header-settings-list__settings{
    position:relative;
    top:1px;
    display:inline-block;
    height:14px;
    width:14px;
    padding:0 20px;
    text-indent:-9999px;
    overflow:hidden;
    background-size:contain;
    background-repeat:no-repeat;
    background-position:center;
    background-image:url(../img/icon-settings.png);
    border:none
}
#header-settings-list__settings:focus,#header-settings-list__settings:hover{
    background-image:url(../img/icon-settings-hover.png)
}
#header-settings-list__logout{
    position:relative;
    top:1px;
    display:inline-block;
    height:14px;
    width:14px;
    padding:0 20px;
    text-indent:-9999px;
    overflow:hidden;
    background-size:contain;
    background-repeat:no-repeat;
    background-position:center;
    background-image:url(../img/icon-logout.png);
    border:none
}
#header-settings-list__logout:focus,#header-settings-list__logout:hover{
    background-image:url(../img/icon-logout-hover.png)
}
.header-list__link-trigger.active{
    color:#4cfadf
}