.banner{
    position:relative;
    top:-30px;
    height:100px;
    margin:0 0 20px 0;
    background-image:url(../img/dotted-incline@2x.png);
    background-color:#a374f8;
    background-size:cover;
    background-position:center center;
    z-index:1
}
.page-not-found-message{
    margin:0 0 50px 0;
    overflow:auto
}
.page-not-found-message h1{
    font-size:2em;
    margin:0 0 20px 0
}
.page-not-found-message ul{
    margin:15px 0 15px 20px;
    padding:0
}
.page-not-found-message ul li{
    position:relative;
    list-style:none;
    line-height:24px
}
.page-not-found-message ul li:before{
    display:block;
    position:absolute;
    top:9px;
    left:-18px;
    content:"";
    width:6px;
    height:6px;
    border-radius:4px;
    background:#ffaa4c
}
.page-not-found-message ul li a{
    color:#3b444c;
    text-decoration:none;
    border-bottom:1px solid #4cfadf;
    padding-bottom:2px
}
@media (min-width:992px){
    .banner{
        height:160px;
        margin:0 0 30px 0
    }
    .page-not-found-message{
        margin:0 0 100px 0
    }
    .page-not-found-message h1{
        font-size:52px
    }
}