*{
    box-sizing:border-box;
    -webkit-font-smoothing:antialiased
}
body{
    position:relative;
    margin:0;
    padding:0;
    font-size:14px;
    font-family:'Open Sans',sans-serif;
    color:#3b444c;
    overflow-x:hidden;
}
body.no-scroll{
    overflow:hidden;
}
a{
    -webkit-transition:all 350ms ease;
    transition:all 350ms ease;
    font-weight:600;
    text-decoration:none
}
a:focus{
    outline:0!important
}
p{
    line-height:22px;
    margin:0 0 20px 0;
}

@media (min-width:992px){
    body{
        margin-top:104px
    }
}

// ---

h1,h2,h3,h4,h5,h6{
    font-family:Montserrat,sans-serif
}
h2+h3{
    font-weight:400;
    padding:20px 0 10px;
    margin:-30px 0 0 0;
    font-size:14px;
    text-transform:uppercase
}
b,strong{
    font-weight:600
}
.text-right{
    text-align:right
}
h1{
    margin:0 0 30px 0
}
.hierarchy-title{
    text-align:center
}
@media (min-width:1024px){
    h1{
        margin:60px 0
    }
}
h4,h5,h6{
    font-size:16px;
    line-height:26px;
    margin-top:40px;
    margin-bottom:20px
}
// header as in h1, h2, etc
.header--underlined{
    border-bottom:2px solid #a374f8;
    padding-bottom:8px;
    margin-bottom:15px;
    width:100%;
    display:block;
    float:left
}
select{
    padding:6px 12px;
    background-color:#fff;
    border:1px solid #3b444c;
    border-radius:6px;
    width:100%;
    height:36px
}