.outer_container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

@media (min-width:992px){
    .outer_container {
        min-height: calc(100vh - 104px);
    }
}

.pyramid{
    display:none;
    width:78%;
    margin:0 1% 0 0;
    float:left
}
.pyramid-content-wrapper{
    min-height:calc(100vh - 531px);
    margin:0 0 25px 0;
    overflow:visible;
    clear:both;
    flex: 1;
}
.pyramid-wrapper{
    display:none;
    overflow:auto
}
.pyramid__hierarchy-level{
    width:100%;
    margin-bottom:10px
}
.pyramid__hierarchy-level--fade-psuedo-after-element:after{
    opacity:.5
}
.pyramid__hierarchy-level--D{
    position:relative
}
.pyramid__hierarchy-level--D:after{
    content:'';
    width:10px;
    height:15px;
    position:absolute;
    bottom:44px;
    left:50%;
    margin-left:-5px;
    background-color:#623587;
    z-index:-1
}
/*here*/
.pyramid__hierarchy-level--D .pyramid__block{
    /*background-color:#623587*/
    background-color: rgba(98,61,150,0.10);
    border-top: 2px solid #623D96;
    border-bottom: 2px solid #623D96;
}
.pyramid__hierarchy-level--C{
    position:relative
}
.pyramid__hierarchy-level--C:after{
    content:'';
    width:10px;
    height:15px;
    position:absolute;
    bottom:44px;
    left:50%;
    margin-left:-5px;
    background-color:#a278f4;
    z-index:-1
}
.pyramid__hierarchy-level--C .pyramid__block{
    /*background-color:#a278f4;*/
    background-color: rgba(40,182,192,0.10);
    border-top: 2px solid #33CCCC;
    border-bottom: 2px solid #33CCCC;

}
.pyramid__hierarchy-level--B{
    position:relative
}
.pyramid__hierarchy-level--B:after{
    content:'';
    width:10px;
    height:15px;
    position:absolute;
    bottom:44px;
    left:50%;
    margin-left:-5px;
    background-color:#50daf6;
    z-index:-1
}
.pyramid__hierarchy-level--B .pyramid__block{
    /*background-color:#50daf6;*/
    background-color: rgba(71,220,249,0.10);
    border-top: 2px solid #47DCF9;
    border-bottom: 2px solid #47DCF9;
}
.pyramid__hierarchy-level-row{
    position:relative;
    /*margin:0 auto 2px auto;*/
    /*here*/
    margin:0 auto 6px auto;
    overflow:hidden;
    /*here*/
    /*border-radius:0 0 8px 8px;*/
    -webkit-backface-visibility:hidden;
    -moz-backface-visibility:hidden;
    -webkit-transform:translate3d(0,0,0);
    -moz-transform:translate3d(0,0,0)
}

/*here*/
/*.pyramid__hierarchy-level-row:after{
    content:'';
    position:absolute;
    top:0;
    left: 0;
    z-index:1;
    display:block;
    width:35px;
    height:100%;
    background-position: top left;
}*/

/*.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--B1:after,
.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--B2:after {
	background:url(../img/svgs/rwa-triangle-negative-space-left-row-b.svg) no-repeat;
}*/

/*.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--C1:after,
.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--C2:after {
	background:url(../img/svgs/rwa-triangle-negative-space-left-row-c.svg) no-repeat;
}*/

/*.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--D1:after,
.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--D2:after {
	background:url(../img/svgs/rwa-triangle-negative-space-left-row-d.svg) no-repeat;
}*/

/*.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--E:after {
	background:url(../img/svgs/rwa-triangle-negative-space-left-row-e.svg) no-repeat;
}*/

.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--B1 .pyramid__block--B1:before,
.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--B2 .pyramid__block--B3:before,
.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--C1 .pyramid__block--C1:before,
.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--C2 .pyramid__block--C4:before,
.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--D1 .pyramid__block--D1:before,
.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--D2 .pyramid__block--D4:before,
.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--E .pyramid__block--E1:before {
    content:'';
    position:absolute;
    top: -2px;
    left: 0;
    z-index:1;
    display:block;
    width:35px;
    height: 52px;
    background-position: 0 0;
}

.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--B1 .pyramid__block--B1:before,
.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--B2 .pyramid__block--B3:before {
    background:url(../img/svgs/rwa-triangle-negative-space-left-row-b.svg) no-repeat;
}

.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--C1 .pyramid__block--C1:before,
.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--C2 .pyramid__block--C4:before {
    background:url(../img/svgs/rwa-triangle-negative-space-left-row-c.svg) no-repeat;
}

.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--D1 .pyramid__block--D1:before,
.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--D2 .pyramid__block--D4:before {
    background:url(../img/svgs/rwa-triangle-negative-space-left-row-d.svg) no-repeat;
}

.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--E .pyramid__block--E1:before {
    background:url(../img/svgs/rwa-triangle-negative-space-left-row-e.svg) no-repeat;
}

/*here*/

.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--B1 .pyramid__block--B2:before,
.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--B2 .pyramid__block--B5:before,
.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--C1 .pyramid__block--C3:before,
.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--C2 .pyramid__block--C6:before,
.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--D1 .pyramid__block--D3:before,
.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--D2 .pyramid__block--D7:before,
.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--E .pyramid__block--E7:before {
    content:'';
    position:absolute;
    top: -2px;
    right: 0;
    z-index:1;
    display:block;
    width:35px;
    height: 52px;
    background-position: 0 0;
}

.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--B1 .pyramid__block--B2:before,
.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--B2 .pyramid__block--B5:before {
    background:url(../img/svgs/rwa-triangle-negative-space-right-row-b.svg) no-repeat;
}

.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--C1 .pyramid__block--C3:before,
.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--C2 .pyramid__block--C6:before {
    background:url(../img/svgs/rwa-triangle-negative-space-right-row-c.svg) no-repeat;
}

.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--D1 .pyramid__block--D3:before,
.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--D2 .pyramid__block--D7:before {
    background:url(../img/svgs/rwa-triangle-negative-space-right-row-d.svg) no-repeat;
}

.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--E .pyramid__block--E7:before {
    background:url(../img/svgs/rwa-triangle-negative-space-right-row-e.svg) no-repeat;
}

/*.pyramid__hierarchy-level-row:before{
    content:'';
    position:absolute;
    right:-3px;
    top:0;
    z-index:1;
    display:block;
    width:38px;
    height:100%;
    background:url(../img/svgs/rwa-triangle-negative-space-right.svg) center top no-repeat;
    background-position: 0 0;
}*/

/*.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--B1:before,
.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--B2:before {
	background:url(../img/svgs/rwa-triangle-negative-space-right-row-b.svg) no-repeat;
}*/

/*.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--C1:before,
.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--C2:before {
	background:url(../img/svgs/rwa-triangle-negative-space-right-row-c.svg) no-repeat;
}*/

/*.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--D1:before,
.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--D2:before {
	background:url(../img/svgs/rwa-triangle-negative-space-right-row-d.svg) no-repeat;
}*/

/*.pyramid__hierarchy-level-row.pyramid__hierarchy-level-row--E:before {
	background:url(../img/svgs/rwa-triangle-negative-space-right-row-e.svg) no-repeat;
}*/

.pyramid__hierarchy-level-row--A{
    display:block;
    width:30%;
    height:149px;
    background:url(../img/svgs/rwa-full-triangle-pyramid-new.svg) center center no-repeat;
    position:relative;
    background-size:cover
}

@media screen and (min-width: 1200px) {
	.pyramid__hierarchy-level-row--A{
		width: 28%;
	}
}

.pyramid__hierarchy-level-row--A:after,.pyramid__hierarchy-level-row--A:before{
    display:none
}
.pyramid__hierarchy-level-row--A .pyramid__block{
    width:100%;
    color:#565153;
    height:100%;
    padding-top:20px;
    padding-left:60px;
    padding-right:60px
}
.pyramid__hierarchy-level-row--B1{
    width:40%;
    position:relative
}

.pyramid__hierarchy-level--B:after,
.pyramid__hierarchy-level--C:after,
.pyramid__hierarchy-level--D:after{
	content: none;
	position: absolute;
	top: 52px;
	left: 0;
	right: 0;
	width: 10px;
	height: 6px;
	margin: 0 auto;
	z-index: 1;
}

@media screen and (min-width: 1024px) {
	.pyramid__hierarchy-level--B:after,
	.pyramid__hierarchy-level--C:after,
	.pyramid__hierarchy-level--D:after{
		content: '';
	}
}

.pyramid__hierarchy-level--B:after{
	background-color: #47DCF9;
}

.pyramid__hierarchy-level--C:after{
	background-color: #33CCCC;
}

.pyramid__hierarchy-level--D:after{
	background-color: #623D96;
}

@media screen and (min-width: 1200px) {
	.pyramid__hierarchy-level-row--B1{
    width: 37%;
  }	
}

.pyramid__hierarchy-level-row--B1 .pyramid__block{
    width:50%;
    color:#565153;
    z-index:0
}
.pyramid__hierarchy-level-row--B1 .pyramid__block--B1 span{
    padding:0 5px 0 25px
}
.pyramid__hierarchy-level-row--B1 .pyramid__block--B2 span{
    padding:0 25px 0 5px
}
.pyramid__hierarchy-level-row--B2{
    width:50%;
}

@media screen and (min-width: 1200px) {
	.pyramid__hierarchy-level-row--B2{
    width: 44%;
	}	
}

.pyramid__hierarchy-level-row--B2 .pyramid__block{
    width:33.33%;
    color:#565153;
    z-index:0
}
.pyramid__hierarchy-level-row--B2 .pyramid__block--B3 span{
    padding:0 5px 0 25px
}
.pyramid__hierarchy-level-row--B2 .pyramid__block--B4 span{
    padding:0 5px
}
.pyramid__hierarchy-level-row--B2 .pyramid__block--B5 span{
    padding:0 25px 0 5px
}
.pyramid__hierarchy-level-row--C1{
  width:60%
}

@media screen and (min-width: 1200px) {
	.pyramid__hierarchy-level-row--C1{
    width: 52%;
	}
}

.pyramid__hierarchy-level-row--C1 .pyramid__block{
    width:33.33%;
    /*color:#fff;*/
    z-index:0
}
.pyramid__hierarchy-level-row--C1 .pyramid__block--C1 span{
    padding:0 5px 0 25px
}
.pyramid__hierarchy-level-row--C1 .pyramid__block--C2 span{
    padding:0 5px
}
.pyramid__hierarchy-level-row--C1 .pyramid__block--C3 span{
    padding:0 25px 0 5px
}
.pyramid__hierarchy-level-row--C2{
    width:70%
}

@media screen and (min-width: 1200px) {
	.pyramid__hierarchy-level-row--C2{
	    width: 59%;
	}
}

.pyramid__hierarchy-level-row--C2 .pyramid__block{
    width:33.33%;
    /*color:#fff;*/
    z-index:0
}
.pyramid__hierarchy-level-row--C2 .pyramid__block--C4 span{
    padding:0 5px 0 25px
}
.pyramid__hierarchy-level-row--C2 .pyramid__block--C5 span{
    padding:0 5px
}
.pyramid__hierarchy-level-row--C2 .pyramid__block--C6 span{
    padding:0 25px 0 5px
}
/*.pyramid__hierarchy-level-row--B1{
    width:40%
}*/
.pyramid__hierarchy-level-row--B1 .pyramid__block{
    width:50%;
    /*color:#565153;*/
    z-index:0
}
.pyramid__hierarchy-level-row--B1 .pyramid__block--B1 span{
    padding:0 5px 0 25px
}
.pyramid__hierarchy-level-row--B1 .pyramid__block--B2 span{
    padding:0 5px
}
.pyramid__hierarchy-level-row--B1 .pyramid__block--B3 span{
    padding:0 25px 0 5px
}
.pyramid__hierarchy-level-row--D1{
    width:80%
}

@media screen and (min-width: 1200px) {
	.pyramid__hierarchy-level-row--D1{
	    width: 67%;
	}
}

.pyramid__hierarchy-level-row--D1 .pyramid__block{
    width:33.33%;
    /*color:#fff;*/
    z-index:0
}
.pyramid__hierarchy-level-row--D1 .pyramid__block--D1 span{
    padding:0 5px 0 25px
}
.pyramid__hierarchy-level-row--D1 .pyramid__block--D2 span{
    padding:0 5px
}
.pyramid__hierarchy-level-row--D1 .pyramid__block--D3 span{
    padding:0 25px 0 5px
}
.pyramid__hierarchy-level-row--D2{
    width:90%
}

@media screen and (min-width: 1200px) {
	.pyramid__hierarchy-level-row--D2{
	    width: 74%;
	}
}

.pyramid__hierarchy-level-row--D2 .pyramid__block{
    width:25%;
    /*color:#fff;*/
    z-index:0
}
.pyramid__hierarchy-level-row--D2 .pyramid__block--D4 span{
    padding:0 5px 0 25px
}
.pyramid__hierarchy-level-row--D2 .pyramid__block--D5 span,.pyramid__hierarchy-level-row--D2 .pyramid__block--D6 span{
    padding:0 5px
}
.pyramid__hierarchy-level-row--D2 .pyramid__block--D7 span{
    padding:0 25px 0 5px
}
.pyramid__hierarchy-level-row--E{
    width:100%;
    margin:0 auto
}

@media screen and (min-width: 1200px) {
	.pyramid__hierarchy-level-row--E{
	    width: 82%;
	}
}

.pyramid__hierarchy-level-row--E .pyramid__block{
    width:14.285%;
    /*color:#fff;*/
    /*background-color:#3b444d*/
    background-color: rgba(59,68,76, 0.10);
    border-top: 2px solid #3B444C;
    border-bottom: 2px solid #3B444C;
}
.pyramid__hierarchy-level-row--E .pyramid__block--E1 span{
    padding:0 5px 0 25px
}
.pyramid__hierarchy-level-row--E .pyramid__block--E2 span,.pyramid__hierarchy-level-row--E .pyramid__block--E3 .pyramid__hierarchy-level-row--E .pyramid__block--E4 span,.pyramid__hierarchy-level-row--E .pyramid__block--E5 span,.pyramid__hierarchy-level-row--E .pyramid__block--E6 span{
    padding:0 5px
}
.pyramid__hierarchy-level-row--E .pyramid__block--E7 span{
    padding:0 25px 0 5px
}
.pyramid__block{
    position:relative;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    height:52px;
    padding:0;
    float:left;
    text-align:center;
    text-transform:capitalize;
    text-decoration:none;
    font-size:12px;
    line-height:16px;
    word-break:break-word;
    white-space:pre-line;
    padding:0 10px;
    color:#565153;
    -webkit-transition:opacity .3s ease;
    transition:opacity .3s ease;
    -webkit-transition-timing-function:linear;
    transition-timing-function:linear
}
.pyramid__block:after{
    content:'';
    display:block;
    position:absolute;
    top:15%;
    right:0;
    height:75%;
    width:1px;
    opacity:1;
    -webkit-animation:none;
    animation:none
}

/*here*/

.pyramid__hierarchy-level--B .pyramid__block:after{
	background-color: #47DCF9;
}

.pyramid__hierarchy-level--C .pyramid__block:after{
	background-color: #33CCCC;
}

.pyramid__hierarchy-level--D .pyramid__block:after{
	background-color: #623D96;
}

.pyramid__hierarchy-level--E .pyramid__block:after{
	background-color: #3B444C;
}

/*here*/

.pyramid__block:last-child:after{
    display:none
}
.pyramid__block--not-evaluated{
    opacity:.5
}
.pyramid__block--evaluated{
    opacity:1
}
.pyramid__block span{
    position:relative
}
.pyramid__block--no-report:hover{
    cursor:default
}
.pyramid__block--no-report:hover span{
    -webkit-transform:none;
    transform:none
}
.pyramid__block--no-report span{
    opacity:.5;
    -webkit-transition:none;
    transition:none
}
.pyramid-sidebar{
    display:none;
    position:absolute;
    width:100%;
    right:0;
    /*padding-left:890px;*/
    font-size:14px;
    line-height:16px;
    font-weight:600;
    font-family:Montserrat,sans-serif;
    /*z-index:1;*/
    /*here*/
    z-index: -1;
    pointer-events:none;
}

/*here*/
.pyramid-sidebar:before{
	content: '';
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: #fff;
	top: 0;
	left: -38.8%;
	transform: skew(31deg);
}

@media screen and (min-width: 1200px) {
	.pyramid-sidebar:before{
		left: -42%;
	}
}

.pyramid-sidebar:after{
	content: '';
	display: block;
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: #fff;
	top: 0;
	right: -82.7%;
	transform: skew(31deg);
}

@media screen and (min-width: 1200px) {
	.pyramid-sidebar:after{
		right: -80%;
	}
}
/*here*/

.pyramid-sidebar__level{
    margin:0 0 10px 0;
    display:table;
    width:100%
}
.pyramid-sidebar__level-inner{
    height:inherit;
    /*here*/
    /*padding:15px 30px 10px 35px;*/
    padding-left: 50%;
    -webkit-transform:none;
    transform:none;
    z-index:1;
    font-size:13px;
    position:relative;
    display:table-cell;
    vertical-align:middle
}
.pyramid-sidebar__level--A{
    margin:0 0 10px 0;
    position:relative;
    background:url(../img/icon-advanced-performance-drivers@2x.png) 0 15% no-repeat;
    background-size:34px 34px;
    /*margin-left:-57px;*/
    /*here*/
    background-position: 50% 12px;
}

@media screen and (min-width: 1200px) {
	.pyramid-sidebar__level--A{
	    background-position: 49% 12px;
	}	
}

.pyramid-sidebar__level--A:after{
    content:'';
    background-color:#cbfef3;
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:-1;
    /*border-radius:6px;*/
    /*opacity:.3;*/
    /*-webkit-transform:skew(33deg);
    -moz-transform:skew(33deg);
    -o-transform:skew(33deg);
    -ms-transform:skew(33deg);*/
}
.pyramid-sidebar__level--A .pyramid-sidebar__level-inner{
    padding-left: 52%;
    padding-right: 33%;
}

@media screen and (min-width: 1200px) {
	.pyramid-sidebar__level--A .pyramid-sidebar__level-inner{
	    padding-left: 50%;
	}	
}

/*here*/
.pyramid-sidebar__level--B .pyramid-sidebar__level-inner{
	padding-top: 15px;
	padding-left: 61%;
  padding-right: 24%;
}

@media screen and (min-width: 1200px) {
	.pyramid-sidebar__level--B .pyramid-sidebar__level-inner{
		padding-top: 0;
		padding-left: 59%;
	  padding-right: 25%;
	}	
}

.pyramid-sidebar__level--C .pyramid-sidebar__level-inner{
	padding-top: 17px;
	padding-left: 68%;
	padding-right: 16%;
}

@media screen and (min-width: 1200px) {
	.pyramid-sidebar__level--C .pyramid-sidebar__level-inner{
		padding-top: 0;
		padding-left: 65%;
		padding-right: 17%;
	}	
}

.pyramid-sidebar__level--D .pyramid-sidebar__level-inner{
	padding-top: 10px;
	padding-left: 75%;
	padding-right: 8%;
	color: #fff;
}

@media screen and (min-width: 1200px) {
	.pyramid-sidebar__level--D .pyramid-sidebar__level-inner{
		padding-top: 0;
		padding-left: 71%;
		padding-right: 13%;
	}
}

.pyramid-sidebar__level--E {
    display: none !important;
}

.pyramid-sidebar__level--E .pyramid-sidebar__level-inner{
	padding-left: 83.5%;
	padding-right: 8%;
	color: #fff;
}

@media screen and (min-width: 1200px) {
	.pyramid-sidebar__level--E .pyramid-sidebar__level-inner{
		padding-left: 77%;
		padding-right: 8%;
	}	
}
/*here*/

.pyramid-sidebar__level--B{
    position:relative;
    background:url(../img/icon-focus@2x.png) 0 13% no-repeat;
    background-size:34px 34px;
    /*margin-left:-113px;*/
    /*here*/
    background-position: 60% 12px;
}

@media screen and (min-width: 1200px) {
.pyramid-sidebar__level--B{
	  background-position: 58% 12px;
	}
}

.pyramid-sidebar__level--B:after{
    content:'';
    background-color:#c7f4fd;
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:-1;
    /*border-radius:6px;*/
    /*opacity:.3;*/
    /*-webkit-transform:skew(33deg);
    -moz-transform:skew(33deg);
    -o-transform:skew(33deg);
    -ms-transform:skew(33deg);*/
}
.pyramid-sidebar__level--C{
    position:relative;
    background:url(../img/icon-business-development@2x.png) 0 13% no-repeat;
    background-size:34px 34px;
    /*margin-left:-184px;*/
    /*here*/
    background-position: 67.5% 10px;
}

@media screen and (min-width: 1200px) {
	.pyramid-sidebar__level--C{
	  background-position: 64% 10px;
	}
}

.pyramid-sidebar__level--C:after{
    content:'';
    /*background-color:#a278f4;*/
    /*here*/
    background-color: #c1f0f0;
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:-1;
    /*border-radius:6px;*/
    /*opacity:.3;*/
    /*-webkit-transform:skew(34deg);
    -moz-transform:skew(34deg);
    -o-transform:skew(34deg);
    -ms-transform:skew(34deg);*/
}
.pyramid-sidebar__level--D{
    position:relative;
    background:url(../img/icon-operational-efficiency@2x.png) 0 13% no-repeat;
    background-size:34px 34px;
    /*margin-left:-259px;*/
    /*here*/
    background-position: 75.5% 11px;
}

@media screen and (min-width: 1200px) {
	.pyramid-sidebar__level--D{
	    background-position: 70.5% 11px;
	}
}

.pyramid-sidebar__level--D:after{
    content:'';
    background-color:#b296c8;
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:-1;
    /*border-radius:6px;*/
    /*opacity:.3;*/
    /*-webkit-transform:skew(34deg);
    -moz-transform:skew(34deg);
    -o-transform:skew(34deg);
    -ms-transform:skew(34deg);*/
}
.pyramid-sidebar__level--E{
    position:relative;
    background:url(../img/icon-compliance@2x.png) 8% 50% no-repeat;
    background-size:34px 34px;
    /*margin-left:-346px;*/
    margin-bottom:0;
    background-position: 81.5% 9px;
}

@media screen and (min-width: 1200px) {
	.pyramid-sidebar__level--E{
	    background-position: 75% 9px;
	}
}

.pyramid-sidebar__level--E:after{
    content:'';
    background-color:#9da1a6;
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:-1;
    /*border-radius:6px;*/
    /*opacity:.3;*/
    /*-webkit-transform:skew(32deg);
    -moz-transform:skew(32deg);
    -o-transform:skew(32deg);
    -ms-transform:skew(31deg);*/
}
.pyramid-sidebar__level--E .pyramid-sidebar__level-inner{
    /*padding:25px 30px 10px 30px;*/
    /*here*/
    /*padding: 15px 30px 10px 60px;*/
}
@media all and (-ms-high-contrast:none),(-ms-high-contrast:active){
    .pyramid__hierarchy-level-row:before{
        background-position:0 -5px
    }
    .pyramid__hierarchy-level-row:after{
        background-position:0 -5px
    }
}
@media (min-width:992px){
    .pyramid-content-wrapper{
        margin:0 0 50px 0
    }
}
@media (min-width:1024px){
    .pyramid{
        display:block
    }
    .pyramid-sidebar{
        display:block;
        /*padding-left:810px;*/
    }
}

/*here*/

@media (min-width:992px){
    .pyramid{
        width:76%
    }
    .pyramid__hierarchy-level{
        width:100%
    }
    /*.pyramid__hierarchy-level-row--A{
        height:149px
    }*/

    .pyramid__hierarchy-level-row--A .pyramid__block{
        height:100%
    }

    /*.pyramid-sidebar{
      padding-left:940px
    }*/

    /*.pyramid-sidebar__level--E{
        margin-left:-164px
    }*/
    /*.pyramid-sidebar__level--E:after{
        -webkit-transform:skew(35deg);
        -moz-transform:skew(35deg);
        -o-transform:skew(35deg);
        -ms-transform:skew(35deg)
    }*/

    /*.pyramid-sidebar__level--D{
        margin-left:-225px
    }*/
    /*.pyramid-sidebar__level--D:after{
        -webkit-transform:skew(37deg);
        -moz-transform:skew(37deg);
        -o-transform:skew(37deg);
        -ms-transform:skew(37deg)
    }*/

    /*.pyramid-sidebar__level--C{
        margin-left:-310px
    }*/
    /*.pyramid-sidebar__level--C:after{
        -webkit-transform:skew(38deg);
        -moz-transform:skew(38deg);
        -o-transform:skew(38deg);
        -ms-transform:skew(38deg)
    }*/

    /*.pyramid-sidebar__level--B{
        margin-left:-390px
    }*/
    /*.pyramid-sidebar__level--B:after{
        -webkit-transform:skew(35deg);
        -moz-transform:skew(35deg);
        -o-transform:skew(35deg);
        -ms-transform:skew(35deg)
    }*/

    /*.pyramid-sidebar__level--A{
        margin-left:-480px
    }*/
    /*.pyramid-sidebar__level--A:after{
        -webkit-transform:skew(34deg);
        -moz-transform:skew(34deg);
        -o-transform:skew(34deg);
        -ms-transform:skew(34deg)
    }*/

}

/*here*/

@media (min-width:1200px){
    .pyramid{
        width:76%
    }
    .pyramid__hierarchy-level{
        width:100%
    }
    .pyramid__hierarchy-level-row--A{
        /*height:175px*/
        /*here*/
        height: 167px;
    }
    .pyramid__hierarchy-level-row--A .pyramid__block{
        height:100%
    }
    /*.pyramid-sidebar{
        padding-left:940px
    }*/
    /*.pyramid-sidebar__level--E{
        margin-left:-57px
    }*/
    /*.pyramid-sidebar__level--E:after{
        -webkit-transform:skew(35deg);
        -moz-transform:skew(35deg);
        -o-transform:skew(35deg);
        -ms-transform:skew(35deg)
    }*/
    /*.pyramid-sidebar__level--D{
        margin-left:-121px
    }*/
    /*.pyramid-sidebar__level--D:after{
        -webkit-transform:skew(37deg);
        -moz-transform:skew(37deg);
        -o-transform:skew(37deg);
        -ms-transform:skew(37deg)
    }*/
    /*.pyramid-sidebar__level--C{
        margin-left:-207px
    }*/
    /*.pyramid-sidebar__level--C:after{
        -webkit-transform:skew(38deg);
        -moz-transform:skew(38deg);
        -o-transform:skew(38deg);
        -ms-transform:skew(38deg)
    }*/
    /*.pyramid-sidebar__level--B{
        margin-left:-290px
    }*/
    /*.pyramid-sidebar__level--B:after{
        -webkit-transform:skew(35deg);
        -moz-transform:skew(35deg);
        -o-transform:skew(35deg);
        -ms-transform:skew(35deg)
    }*/
    /*.pyramid-sidebar__level--A{
        margin-left:-390px
    }*/
    /*.pyramid-sidebar__level--A:after{
        -webkit-transform:skew(34deg);
        -moz-transform:skew(34deg);
        -o-transform:skew(34deg);
        -ms-transform:skew(34deg)
    }*/
}