@import url(https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,700);

@font-face{
    font-family:icomoon;
    src:url(../fonts/rwa-icons/icomoon.eot?3xiggg);
    src:url(../fonts/rwa-icons/icomoon.eot?3xiggg#iefix) format("embedded-opentype"),url(../fonts/rwa-icons/icomoon.ttf?3xiggg) format("truetype"),url(../fonts/rwa-icons/icomoon.woff?3xiggg) format("woff"),url(../fonts/rwa-icons/icomoon.svg?3xiggg#icomoon) format("svg");
    font-weight:400;
    font-style:normal
}
[class*=" icon-"],[class^=icon-]{
    font-family:icomoon!important;
    speak:none;
    font-style:normal;
    font-weight:400;
    font-variant:normal;
    text-transform:none;
    line-height:1;
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;
    font-size:22px;
    margin-right:8px
}
.icon-phone:before{
    content:"\e909";
    vertical-align:middle
}
.icon-email:before{
    content:"\e90d";
    vertical-align:middle
}
.icon-iconmonstr-arrow-left:before{
    content:"\e900";
    vertical-align:middle
}
.icon-iconmonstr-arrow-right:before{
    content:"\e901";
    vertical-align:middle
}