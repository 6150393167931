.search-wrapper {
  position: relative;
  padding: 0 10px 0 20px;

  input {
    width: 170px;
    height: 30px;
    padding: 5px 8px;
    border: 1px solid rgba(0,0,0,.1);
    transition: all .3s ease;

    &:focus {
      outline: none;
      border-color: #4cfadf;
    }
  }
}

.search-result-list {
  position: absolute;
  top: 38px;
  right: 10px;
  display: block;
  width: 320px;
  height: auto;
  max-height: calc(60vh - 38px);
  margin: 0;
  padding: 0;
  background-color: #fff;
  border: 1px solid rgba(0,0,0,.1);
  overflow: auto;
  z-index: 1001;

  // .search-result-list__item
  &__item {
    list-style: none;
  }

  // .search-result-list__link
  &__link {
    display: inline-block;
    width: 100%;
    padding: 10px;
    color: #3b444c;
    background-color: #fff;
    line-height: 1.4;
    text-align: left;

    &:hover {
      color: #a278f4;
      background-color: #f5f5f5;
    }
  }
}

.mobile-search-outer {
  padding: 15px !important;
}

.mobile-search-wrapper {

  input {
    width: 100%;
    height: 40px;
    padding: 5px 8px;
    border: 1px solid rgba(0,0,0,.1);
    transition: all .3s ease;

    &:focus {
      outline: none;
      border-color: #4cfadf;
    }
  }
}

.mobile-search-result-list {
  margin: 0 !important;
  padding: 0 !important;

  // .mobile-search-result-list:not(:empty)
  &:not(:empty) {
    margin-top: 15px !important;
  }

  // .mobile-search-result-list__item
  &__item {
    margin: 0;
  }

  // .mobile-search-result-list__link
  &__link {
    background-color: #2d2d2d !important;
  }
}