.most-viewed-list,.view-hierarchy-list{
    position:relative;
    top:0;
    right:0;
    margin:0;
    padding:0;
    border: 1px solid transparent;
    overflow: hidden;
    z-index:1
}
.most-viewed-list__item,.view-hierarchy-list__item{
    list-style-type:none;
    display:block;
    margin:0;
    padding:0;
    height: 40px;
}
.most-viewed-list__link,.view-hierarchy-list__link{
    display:inline-block;
    width:100%;
    min-height: 40px;
    position:relative;
    color:#3b444c;
    padding:10px 0;
    border-bottom:1px solid rgba(0,0,0,.1);
    white-space:nowrap;
    text-overflow:ellipsis;
    overflow:hidden
}
.most-viewed-list__link:hover,.view-hierarchy-list__link:hover,
.most-viewed-list__link:focus,.view-hierarchy-list__link:focus{
    color:#a278f4;
    // background-color: #f5f5f5;
}
.most-viewed-list__link--active,.view-hierarchy-list__link--active{
    color:#a278f4
}
.most-viewed-list-container,.view-hierarchy-list-container{
    margin-bottom:30px
}
.most-viewed-list-container h1,.view-hierarchy-list-container h1{
    margin-top:0;
    font-size: 16px;
    line-height: 26px;
}
.most-viewed-list-container{
    margin-top:30px
}
.view-evaluation-select-container{
    margin:0 0 30px 0
}
@media (min-width:769px){
    .most-viewed-list-container{
        margin-top:0
    }
}
@media (min-width:1024px){
    .most-viewed-list-container,.view-hierarchy-list-container{
        position:absolute;
        top:0;
        width:210px;
        margin-bottom:0
    }
    .view-hierarchy-list-container{
        left:15px
    }
    .most-viewed-list-container{
        right:15px
    }
    .view-evaluation-select-container{
        position:absolute;
        top:-647px;
        width:250px;
        right:0;
        margin:0
    }
}
@media (min-width:1200px){
    .most-viewed-list-container,.view-hierarchy-list-container{
        width:250px
    }
    .view-evaluation-select-container{
        top:-628px
    }
}