body.select-site-page {

  #header,
  .footer-wrapper {
    display: none !important;
  }
}

.select-site-wrapper {

  .header--underlined {
    margin-top: 0;
  }

  .view-hierarchy-list {
    margin: 0 0 50px 0;
  }

}