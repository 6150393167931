.footer-wrapper{
    position:relative;
    width:100%;
    height:auto;
    float:left
}
.footer{
    position:relative;
    width:100%;
    height:auto;
    background:#3b444c;
    color:#fff;
    padding:40px 0;
    float:left;
    z-index:2
}
@media (min-width:768px){
    .footer{
        padding:40px 0 20px 0
    }
}
.footer .footer_block{
    display:block;
    float:left;
    width:100%
}
.footer a{
    padding-bottom:0;
    -webkit-transition:all .2s ease;
    transition:all .2s ease;
    text-decoration:none;
    border-bottom:1px solid #4cfadf
}
.footer a:focus,.footer a:hover{
    color:#4cfadf;
    border:0
}
.footer a,.footer h1,.footer h2,.footer h3,.footer h4,.footer h5,.footer h6,.footer li,.footer p{
    color:#fff;
    font-size:14px
}
.footer h3{
    text-transform:uppercase;
    margin-top:0;
    margin-bottom:20px
}
.footer h4{
    margin-bottom:12px
}
.footer ul{
    list-style:none;
    display:block;
    padding:0;
    margin:35px 0 15px 0
}
.footer ul li{
    margin-bottom:0
}
.footer nav ul{
    margin:0
}
.footer nav li,.footer nav li a{
    display:block
}
.footer nav a{
    border-bottom:1px solid rgba(255,255,255,.1);
    padding:10px 0
}
@media (max-width:480px){
    .footer nav li:last-child a{
        border-bottom:0
    }
}
@media (min-width:480px){
    .footer nav a{
        text-align:right
    }
}
.footer nav a:focus,.footer nav a:hover{
    border-bottom:1px solid rgba(255,255,255,.1)
}
.footer .copyright{
    text-align:left
}
.footer .copyright p{
    margin-bottom:0
}
@media (min-width:768px){
    .footer .copyright{
        text-align:right
    }
}