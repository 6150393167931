.minimize-section{
    position:relative;
    bottom:0;
    left:0;
    right:0;
    width:100%;
    display:block;
    padding:0 0 30px 0;
    overflow:auto
}
.minimize-section__title{
    display:inline-block;
    margin:0;
    font-size:22px;
    line-height:22px;
    text-transform:capitalize
}
.minimize-section h3{
    line-height:28px
}
.minimize-list-wrapper{
    position:relative;
    width:100%;
    height:auto;
    padding:0;
    z-index:1
}
.minimize-list{
    margin:20px 0 0 0;
    padding:0;
    -webkit-transition:all .3s ease;
    transition:all .3s ease
}
.minimize-list__item{
    display:inline-block;
    width:100%;
    float:left;
    padding:0 5px;
    text-align:center
}
.minimize-list__item-inner{
    width:100%;
    height:100%;
    -webkit-transform:scale(.85,.85);
    transform:scale(.85,.85);
    opacity:0
}
.minimize-list__item:last-child{
    margin:0
}
.minimize-list__link{
    display:inline-block;
    width:100%;
    color:#565153
}
.minimize-list__link:focus,.minimize-list__link:hover{
    color:#4cfadf
}
.minimize-list__link:hover img{
    -webkit-transform:scale(1.1,1.1);
    transform:scale(1.1,1.1)
}
.minimize-list__img{
    position:relative;
    width:100%;
    height:110px;
    margin:0 0 10px 0;
    border-radius:10px;
    overflow:hidden
}
.minimize-list__img img{
    width:77px!important;
    height:77px;
    margin:15px auto 0 auto;
    -webkit-transition:all .3s ease;
    transition:all .3s ease;
    -webkit-backface-visibility:hidden;
    backface-visibility:hidden;
    -webkit-transform:translateZ(0) scale(1,1);
    transform:translateZ(0) scale(1,1)
}
.minimize-list__text{
    display:inline-block;
    width:100%;
    padding:0 5px
}
.minimize-list-controls{
    display:none;
    position:relative;
    display:block;
    overflow:visible
}
.minimize-list-control{
    position:absolute;
    top:10px;
    display:inline-block;
    width:40px;
    height:40px;
    background-size:20px 40px;
    background-repeat:no-repeat;
    -webkit-transition:none;
    transition:none
}
.minimize-list-control--prev{
    display:none;
    left:5px;
    background-image:url(../img/svgs/iconmonstr-arrow-left-orange.svg);
    background-position:0 50%
}
.minimize-list-control--prev-active{
    display:block!important
}
.minimize-list-control--next{
    display:none;
    right:5px;
    background-image:url(../img/svgs/iconmonstr-arrow-right-orange.svg);
    background-position:100% 50%
}
@media (min-width:769px){
    .minimize-section{
        padding:0 0 55px 0
    }
    .minimize-section__title{
        font-size:32px;
        line-height:42px
    }
    .minimize-list{
        margin:35px 0 0 0
    }
    .minimize-list-controls{
        display:block
    }
    .minimize-list-control{
        top:70px
    }
    .minimize-list-control--prev{
        left:-30px
    }
    .minimize-list-control--next{
        right:-30px
    }
}
.minimize-list__link[data-hierarchy-level="E"] .minimize-list__img{
    background-color:#3b444d
}
.minimize-list__link[data-hierarchy-level="D"] .minimize-list__img{
    background-color:#623587
}
.minimize-list__link[data-hierarchy-level="C"] .minimize-list__img{
    background-color:#c1f0f0;
}
.minimize-list__link[data-hierarchy-level="B"] .minimize-list__img{
    background-color:#50daf6
}
.minimize-list__link[data-hierarchy-level="A"] .minimize-list__img{
    background-color:#5dfbd7
}
.minimize-list__link[data-hierarchy-level="T"] .minimize-list__img{
    background-color:#00bec6
}