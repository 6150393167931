#page-loading-indicator{
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    height:100%;
    width:100%;
    background-color:#fff;
    z-index:9999
}
#loading-spinner{
    display:none;
    height:100px;
    width:100px;
    opacity:1
}
#outdated-browser-warning{
    display:none;
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    height:100%;
    width:100%;
    color:#000;
    background-color:#fff;
    z-index:9999
}
#outdated-browser-warning-message{
    width:100%;
    max-width:100%;
    margin:30px auto 0 auto;
    padding:0 15px
}
@media (min-width:768px){
    #outdated-browser-warning-message{
        width:940px;
        max-width:940px;
        margin:50px auto 0 auto;
        padding:0
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
    #outdated-browser-warning {
        display: block;
    }
}