.owl-carousel .animated{
    -webkit-animation-duration:1s;
    animation-duration:1s;
    -webkit-animation-fill-mode:both;
    animation-fill-mode:both
}
.owl-carousel .owl-animated-in{
    z-index:0
}
.owl-carousel .owl-animated-out{
    z-index:1
}
.owl-carousel .fadeOut{
    -webkit-animation-name:fadeOut;
    animation-name:fadeOut
}
@-webkit-keyframes fadeOut{
    0%{
        opacity:1
    }
    100%{
        opacity:0
    }
}
@keyframes fadeOut{
    0%{
        opacity:1
    }
    100%{
        opacity:0
    }
}
.owl-height{
    -webkit-transition:height .5s ease-in-out;
    -moz-transition:height .5s ease-in-out;
    -ms-transition:height .5s ease-in-out;
    -o-transition:height .5s ease-in-out;
    transition:height .5s ease-in-out
}
.owl-carousel{
    display:none;
    width:100%;
    -webkit-tap-highlight-color:transparent;
    position:relative;
    z-index:1
}
.owl-carousel .owl-stage{
    position:relative;
    -ms-touch-action:pan-Y
}
.owl-carousel .owl-stage:after{
    content:".";
    display:block;
    clear:both;
    visibility:hidden;
    line-height:0;
    height:0
}
.owl-carousel .owl-stage-outer{
    position:relative;
    overflow:hidden;
    -webkit-transform:translate3d(0,0,0)
}
.owl-carousel .owl-controls .owl-dot,.owl-carousel .owl-controls .owl-nav .owl-next,.owl-carousel .owl-controls .owl-nav .owl-prev{
    cursor:pointer;
    cursor:hand;
    -webkit-user-select:none;
    -khtml-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none
}
.owl-carousel.owl-loaded{
    display:block
}
.owl-carousel.owl-loading{
    opacity:0;
    display:block
}
.owl-carousel.owl-hidden{
    opacity:0
}
.owl-carousel .owl-refresh .owl-item{
    display:none
}
.owl-carousel .owl-item{
    position:relative;
    min-height:1px;
    float:left;
    -webkit-backface-visibility:hidden;
    -webkit-tap-highlight-color:transparent;
    -webkit-touch-callout:none;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none
}
.owl-carousel .owl-item img{
    display:block;
    width:100%;
    -webkit-transform-style:preserve-3d
}
.owl-carousel.owl-text-select-on .owl-item{
    -webkit-user-select:auto;
    -moz-user-select:auto;
    -ms-user-select:auto;
    user-select:auto
}
.owl-carousel .owl-grab{
    cursor:move;
    cursor:-webkit-grab;
    cursor:-o-grab;
    cursor:-ms-grab;
    cursor:grab
}
.owl-carousel.owl-rtl{
    direction:rtl
}
.owl-carousel.owl-rtl .owl-item{
    float:right
}
.no-js .owl-carousel{
    display:block
}
.owl-carousel .owl-item .owl-lazy{
    opacity:0;
    -webkit-transition:opacity .4s ease;
    -moz-transition:opacity .4s ease;
    -ms-transition:opacity .4s ease;
    -o-transition:opacity .4s ease;
    transition:opacity .4s ease
}
.owl-carousel .owl-item img{
    transform-style:preserve-3d
}
.owl-carousel .owl-video-wrapper{
    position:relative;
    height:100%;
    background:#000
}
.owl-carousel .owl-video-play-icon{
    position:absolute;
    height:80px;
    width:80px;
    left:50%;
    top:50%;
    margin-left:-40px;
    margin-top:-40px;
    background:url(owl.video.play.png) no-repeat;
    cursor:pointer;
    z-index:1;
    -webkit-backface-visibility:hidden;
    -webkit-transition:scale .1s ease;
    -moz-transition:scale .1s ease;
    -ms-transition:scale .1s ease;
    -o-transition:scale .1s ease;
    transition:scale .1s ease
}
.owl-carousel .owl-video-play-icon:hover{
    -webkit-transition:scale(1.3,1.3);
    -moz-transition:scale(1.3,1.3);
    -ms-transition:scale(1.3,1.3);
    -o-transition:scale(1.3,1.3);
    transition:scale(1.3,1.3)
}
.owl-carousel .owl-video-playing .owl-video-play-icon,.owl-carousel .owl-video-playing .owl-video-tn{
    display:none
}
.owl-carousel .owl-video-tn{
    opacity:0;
    height:100%;
    background-position:center center;
    background-repeat:no-repeat;
    -webkit-background-size:contain;
    -moz-background-size:contain;
    -o-background-size:contain;
    background-size:contain;
    -webkit-transition:opacity .4s ease;
    -moz-transition:opacity .4s ease;
    -ms-transition:opacity .4s ease;
    -o-transition:opacity .4s ease;
    transition:opacity .4s ease
}
.owl-carousel .owl-video-frame{
    position:relative;
    z-index:1
}
.owl-theme .owl-controls{
    margin-top:10px;
    text-align:center
}
.owl-theme .owl-controls .owl-buttons div{
    color:#fff;
    display:inline-block;
    zoom:1;
    margin:5px;
    padding:3px 10px;
    font-size:12px;
    -webkit-border-radius:30px;
    -moz-border-radius:30px;
    border-radius:30px;
    background:#869791;
    filter:Alpha(Opacity=50);
    opacity:.5
}
.owl-theme .owl-controls.clickable .owl-buttons div:hover{
    filter:Alpha(Opacity=100);
    opacity:1;
    text-decoration:none
}
.owl-theme .owl-controls .owl-page{
    display:inline-block;
    zoom:1
    /*IE7 life-saver */
}
.owl-theme .owl-controls .owl-page span{
    display:block;
    width:12px;
    height:12px;
    margin:5px 7px;
    filter:Alpha(Opacity=50);
    opacity:.5;
    -webkit-border-radius:20px;
    -moz-border-radius:20px;
    border-radius:20px;
    background:#869791
}
.owl-theme .owl-controls .owl-page.active span,.owl-theme .owl-controls.clickable .owl-page:hover span{
    filter:Alpha(Opacity=100);
    opacity:1
}
.owl-theme .owl-controls .owl-page span.owl-numbers{
    height:auto;
    width:auto;
    color:#fff;
    padding:2px 10px;
    font-size:12px;
    -webkit-border-radius:30px;
    -moz-border-radius:30px;
    border-radius:30px
}
.owl-item.loading{
    min-height:150px;
    background:url(AjaxLoader.gif) no-repeat center center
}
