.owl-carousel{
    position:relative;
    min-height:136px;
}

.owl-carousel--empty:before{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:center;
    -ms-flex-pack:center;
    justify-content:center;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    text-align:center;
    width:calc(100% - 10px);
    height:calc(100% - 3px);
    margin:0 0 0 5px;
    padding:0 15px;
    position:absolute;
    top:0;
    left:0;
    content:'Once a report has been opened it will be added to this area.';
    color:rgba(0,0,0,.6);
    background-color:rgba(0,0,0,.1);
    border-radius:10px;
    box-sizing:border-box;
}
.owl-theme .owl-controls .owl-page{
    display:inline-block;
}
.owl-theme .owl-controls .owl-page span{
    background:none repeat scroll 0 0 #869791;
    border-radius:2px;
    display:block;
    height:12px;
    margin:5px 7px;
    opacity:.5;
    width:12px
}