.pyramid-mobile{
    display:block
}
.pyramid-mobile-accordion{
    margin:0;
    padding:0
}
.pyramid-mobile-accordion__item{
    display:block;
    list-style:none
}
.pyramid-mobile-accordion__item:last-child{
    border:none
}
.pyramid-mobile-accordion__trigger{
    position:relative;
    display:block;
    padding:15px;
    color:#565153;
    border-bottom:2px solid #fff
}
.pyramid-mobile-accordion__trigger:after{
    position:absolute;
    display:inline-block;
    top:18px;
    right:15px;
    content:'';
    height:10px;
    width:18px;
    background-image:url(../img/svgs/iconmonstr-arrow-down-white.svg);
    background-repeat:no-repeat;
    background-size:contain;
    -webkit-transition:all .35s ease;
    transition:all .35s ease;
}
.pyramid-mobile-accordion__trigger.open:after{
    -webkit-transform:rotate(180deg);
    transform:rotate(180deg);
}
.pyramid-mobile-accordion__trigger--E{
    color:#fff;
    background-color:#3b444d;
}
.pyramid-mobile-accordion__trigger--E:after{
    background-image:url(../img/svgs/iconmonstr-arrow-down-white.svg)
}
.pyramid-mobile-accordion__trigger--D{
    color:#fff;
    background-color:#623587;
}
.pyramid-mobile-accordion__trigger--C{
    color:#565153;
    background-color:#c1f0f0;
}
.pyramid-mobile-accordion__trigger--C:after{
    background-image:url(../img/svgs/iconmonstr-arrow-down-white.svg)
}
.pyramid-mobile-accordion__trigger--B{
    color:#565153;
    background-color:#50daf6;
}
.pyramid-mobile-accordion__trigger--B:after{
    background-image:url(../img/svgs/iconmonstr-arrow-down-white.svg)
}
.pyramid-mobile-accordion__trigger--A{
    color:#565153;
    background-color:#5dfbd7;
    border-bottom:none;
}
.pyramid-mobile-accordion__trigger--A:after{
    background-image:url(../img/svgs/iconmonstr-arrow-down-white.svg)
}
.pyramid-mobile-accordion__content{
    display:none;
    margin:0;
    padding:0;
    overflow:auto;
}
.pyramid-mobile-accordion__content--E .pyramid-mobile-accordion__content-link{
    color:#fff;
    background-color:#677787;
}
.pyramid-mobile-accordion__content--D .pyramid-mobile-accordion__content-link{
    color:#fff;
    background-color:#7d43ac;
}
.pyramid-mobile-accordion__content--C .pyramid-mobile-accordion__content-link{
    color:#565153;
    background-color:#c1f0f0;
}
.pyramid-mobile-accordion__content--B .pyramid-mobile-accordion__content-link{
    color:#565153;
    background-color:#68dff7;
}
.pyramid-mobile-accordion__content--A .pyramid-mobile-accordion__content-link{
    color:#565153;
    background-color:#76fcdd;
}
.pyramid-mobile-accordion__content-item{
    border-bottom:1px solid #fff;
}
.pyramid-mobile-accordion__content-item--no-report{
    display:none
}
.pyramid-mobile-accordion__content-link{
    display:inline-block;
    width:100%;
    padding:15px 45px 15px 15px;
    color:#565153
}
.pyramid-accordions-content{
    display:block;
    margin:30px 0 50px 0;
}
.pyramid-accordions-content__projects,
.pyramid-accordions-content__most-used{
    display:none;
    margin:15px 0 25px 0;
}
.pyramid-accordions-content__videos{
    margin:15px 0;
}
.pyramid-accordions-content__heading{
    position:relative;
    display:block;
    width:100%;
    margin:15px 0;
    font-size:16px;
    line-height:26px;
    color:#3b444c;
    padding-bottom:8px;
    border-bottom:2px solid #a374f8
}
.pyramid-accordions-content__heading.pyramid-accordions-content__list-link-trigger:after{
    top:10px
}
.pyramid-accordions-content .header-list__link:focus,.pyramid-accordions-content .header-list__link:hover{
    color:#a374f8
}
.pyramid-accordions-content__list,.pyramid-accordions-content__sub-list{
    padding:0
}
.pyramid-accordions-content__list{
    display:none
}
.pyramid-accordions-content__sub-list{
    display:none
}
.pyramid-accordions-content__list-item{
    list-style:none
}
.pyramid-accordions-content__list-item a{
    text-transform:capitalize
}
.pyramid-accordions-content__sub-list-item{
    list-style:none
}
.pyramid-accordions-content__sub-list-item a{
    padding-left:25px;
    border-bottom:1px solid rgba(0,0,0,.1)
}
.pyramid-accordions-content__list-link{
    position:relative;
    display:inline-block;
    width:100%;
    padding:15px 0;
    border-bottom:1px solid rgba(0,0,0,.1);
    color:#3b444c
}
.pyramid-accordions-content__list-link:focus,.pyramid-accordions-content__list-link:hover{
    color:#a374f8
}
.pyramid-accordions-content__list-link-trigger:after{
    position:absolute;
    display:inline-block;
    top:18px;
    right:15px;
    content:'';
    height:10px;
    width:18px;
    background-image:url(../img/svgs/iconmonstr-arrow-down-black.svg);
    background-repeat:no-repeat;
    background-size:contain;
    -webkit-transition:all .35s ease;
    transition:all .35s ease
}
.pyramid-accordions-content__list-link-trigger.open{
    color:#a278f4
}
.pyramid-accordions-content__list-link-trigger.open:after{
    -webkit-transform:rotate(180deg);
    transform:rotate(180deg)
}
@media (min-width:769px){
    .pyramid-accordions-content{
        display:none
    }
}
@media (min-width:1024px){
    .pyramid-mobile{
        display:none
    }
}