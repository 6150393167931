#main-content{
    position:relative
}
#single-report-iframe-wrapper{
    display:none;
    height: 100%;
}
.modal{
    display:none;
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    width:100%;
    height:100%;
    margin:0 auto;
    background-color:rgba(86,81,83,.9);
    overflow:hidden;
    z-index:9999999
}
.modal__inner{
    position:relative;
    top:0;
    width:100%;
    height:100%;
    margin:0 auto;
    padding:0;
    -webkit-transform:scale(.75,.75);
    transform:scale(.75,.75);
    -webkit-transform-origin:50% 50%;
    transform-origin:50% 50%;
    background-color:#fff;
    overflow:hidden;
    opacity:0
}
.modal__inner-header{
    position:relative;
    display:block;
    width:100%;
    min-height:50px;
    margin:0;
    padding:15px;
    color:#fff;
    background-color:#3b444c;
    overflow:auto
}
.modal__inner-header a{
    color:#fff
}
.modal__inner-title{
    display:inline-block;
    width:65%;
    min-height:22px;
    margin:0;
    padding:0;
    font-size:16px;
    float:left
}
.modal__inner-content{
    position:absolute;
    width:100%;
    height:calc(100% - 50px);
    background-color:#fff;
    -webkit-overflow-scrolling: touch !important;
    /*overflow: scroll !important;*/
    overflow: auto !important;
}
.modal__inner-content-multi-report-list{
    display:none;
    margin:0 25px;
    padding:0;
    opacity:0;
    -webkit-transform:scale(.85,.85);
    transform:scale(.85,.85);
    overflow:hidden
}
.modal__inner-content-multi-report-list-item{
    position:relative;
    top:10px;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:start;
    -ms-flex-pack:start;
    justify-content:flex-start;
    width:100%;
    margin:0 0 30px 0;
    float:left;
    list-style:none
}
.modal__inner-content-multi-report-list-item:last-child{
    margin:0 0 30px 0
}
.modal__inner-content-multi-report-list-link{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    width:100%;
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    padding:0 30px 0 0;
    color:#565153;
    -webkit-transition:color .3s ease;
    transition:color .3s ease
}
.modal__inner-content-multi-report-list-link img{
    width:50px;
    height:50px;
    margin:0 10px 0 0
}
.modal__inner-content-multi-report-list-link:focus,.modal__inner-content-multi-report-list-link:hover{
    color:#4cfadf
}
.modal__inner--single-report #single-report-iframe,.modal__inner--single-report .modal__video-button{
    display:block
}
.modal__inner--single-report .modal__video-container{
    display: none; /*display block added on js event*/
}
.modal__inner--multiple-report .modal__inner-content-multi-report-list{
    display:block
}
.modal__inner--multiple-report .modal__inner-content{
    padding:30px 15px
}
.modal__inner--multiple-report .modal__inner-content-multi-report-list-link--no-report{
    cursor:default;
    opacity:.75
}
.modal__inner--multiple-report .modal__inner-content-multi-report-list-link--no-report:focus,.modal__inner--multiple-report .modal__inner-content-multi-report-list-link--no-report:hover{
    color:#565153
}
.modal__inner--multiple-report .modal__inner-content-multi-report-list-link--no-report:focus img,.modal__inner--multiple-report .modal__inner-content-multi-report-list-link--no-report:hover img{
    -webkit-transform:none!important;
    transform:none!important
}
.modal__buttons{
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -webkit-box-pack:end;
    -ms-flex-pack:end;
    justify-content:flex-end;
    width:35%;
    float:right;
    overflow:visible
}
.modal__buttons .modal__close-button{
    position:relative;
    font-size:14px;
    float:right;
    margin-left:15px
}
.modal__buttons .modal__close-button:before{
    content:'';
    display:inline-block;
    height:22px;
    width:22px;
    background-image:url(../img/svgs/icon-close.svg);
    background-repeat:no-repeat;
    background-size:cover;
    vertical-align:middle
}
.modal__buttons .modal__back-button{
    display:none;
    position:relative;
    font-size:14px;
    float:right;
    margin-left:15px
}
.modal__buttons .modal__back-button:before{
    content:'';
    display:inline-block;
    height:22px;
    width:22px;
    background-image:url(../img/svgs/icon-back.svg);
    background-repeat:no-repeat;
    background-size:cover;
    vertical-align:middle
}
.modal__buttons .modal__video-button{
    display:none;
    position:relative
}
.modal__buttons .modal__video-button:before{
    content:'';
    display:inline-block;
    height:22px;
    width:22px;
    background-image:url(../img/svgs/icon-video.svg);
    background-repeat:no-repeat;
    background-size:cover;
    vertical-align:middle
}
.modal__heading{
    display:block;
    margin:25px 0 0 0;
    font-size:36px
}
.modal__video-container{
    display:none;
    position:absolute;
    top:0;
    right:-100%;
    width:100%;
    height:100%;
    padding:15px;
    background-color:#a374f8;
    color:#fff
}
.modal__video-container-content{
    height:100%
}
.modal__video-container-content-wrapper{
    position:relative;
    margin:0;
    padding:0;
    height:100%;
    color:#565153
}
.modal__video-container-content-title{
    display:block;
    margin:15px 0;
    color:#fff
}
.modal__video-container-content-description-outer{
    position:absolute;
    top:0;
    right:0;
    bottom:45px;
    left:0
}
.modal__video-container-content-description-inner{
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    height:100%;
    padding:0 5px 0 0;
    color:#fff;
    overflow-wrap:break-word;
    word-wrap:break-word;
    line-height:22px;
    overflow-y:auto
}
.modal__video-container-content-description-inner::-webkit-scrollbar{
    width:8px
}
.modal__video-container-content-description-inner::-webkit-scrollbar-track{
    background-color:rgba(0,0,0,.05)
}
.modal__video-container-content-description-inner::-webkit-scrollbar-thumb{
    background-color:rgba(0,0,0,.15)
}
.modal__video-container-controls{
    text-align:right
}
.modal__video-container-control{
    color:#fff
}
.modal__video-container-control--minimize:before{
    content:'';
    display:inline-block;
    height:22px;
    width:22px;
    margin-right:6px;
    background-image:url(../img/svgs/icon-minimize.svg);
    background-repeat:no-repeat;
    background-size:cover;
    vertical-align:middle
}
.modal__video-container-control--close:before{
    content:'';
    display:inline-block;
    height:22px;
    width:22px;
    background-image:url(../img/svgs/icon-close.svg);
    background-repeat:no-repeat;
    background-size:cover;
    vertical-align:middle
}
@media (min-width:768px){
    .modal__inner-title{
        width:85%;
        padding:0 30px 0 0;
        font-size:20px
    }
    .modal__inner-header{
        display:-webkit-box;
        display:-ms-flexbox;
        display:flex;
        -webkit-box-pack:justify;
        -ms-flex-pack:justify;
        justify-content:space-between;
        -webkit-box-align:start;
        -ms-flex-align:start;
        align-items:flex-start
    }
    .modal__inner-content-multi-report-list-item{
        margin:0 0 50px 0
    }
    .modal__buttons{
        -webkit-box-pack:end;
        -ms-flex-pack:end;
        justify-content:flex-end;
        width:15%
    }
}
@media (min-width:769px){
    .modal__video-container{
        right:-320px;
        width:320px
    }
    .modal__video-container-content-description-outer{
        bottom:50px
    }
}
@media (min-width:1024px){
    .modal__inner{
        top:7.5%;
        width:90%;
        height:85%;
        -webkit-transform:scale(.85,.85);
        transform:scale(.85,.85)
    }
    .modal__inner-title{
        width:88%
    }
    .modal__inner-content-multi-report-list-item{
        width:50%
    }
    .modal__buttons{
        width:12%
    }
}
@media (min-width:1200px){
    .modal__inner{
        width:940px;
        height:70%;
        top:15%
    }
    .modal__inner.modal__inner--multiple-report{
        border-radius:10px
    }
}